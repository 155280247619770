<template>
  <div class="mx-7 flex items-center justify-center"> 
    <img :src="require('../assets/icons/raditubelogo.svg')" alt=""> 
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style lang="scss" scoped>

</style>